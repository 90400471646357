<template>
    <div class="home">
        <div class="be">
            <img alt="Alcon" src="../assets/intro.png">
        </div>

        <h1>
            IV Międzynarodowa <br />
            Konferencja<br />
            Naukowo-Szkoleniowa <br />
            <strong>GlauCat 2021</strong>
        </h1>

        <div class="buttons" v-if="!token">
            <router-link to="/register" class="button">Zarejestruj się</router-link>
            <router-link to="/login" class="button">Zaloguj się</router-link>
        </div>
        <div class="buttons" v-if="token">
            <router-link to="/dashboard" class="button">Przejdź do konkursu</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                'token': localStorage.getItem('token'),
            }
        },
    }
</script>
